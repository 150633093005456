<template lang="html">
  <div>
    <div class="page inner-container record--detail">
      <!-- Record Detail wrapper -->
      <div class="record--detail--wrapper">
        <div class="row">
          <div class="scrollTrigger--wrapper"></div>
          <div class="col-12 col-xl-8">
            <div class="record--detail--gallery--wrapper">
              <div class="group--wrapper">
                <swiper
                  ref="mySwiper"
                  :options="swiperOptions"
                  @slideChangeTransitionEnd="handleSlideChange"
                >
                  <swiper-slide v-for="item in recordDetail.gallery">
                    <div class="swiper-slide-wrapper">
                      <div class="swiper-zoom-container">
                        <div class="rotate-wrapper swiper-zoom-target">
                          <img
                            draggable="false"
                            :src="
                              `${s3RootPath}${item.image}`
                                | imageResizedPath('full')
                            "
                            class="swiper-lazy"
                          />
                        </div>
                      </div>
                    </div>
                    <div
                      class="swiper-lazy-preloader swiper-lazy-preloader-white"
                    ></div>
                  </swiper-slide>
                  <!-- <swiper-slide>
										<div class="swiper-slide-wrapper">
											<div class="swiper-zoom-container">
												<div class="rotate-wrapper swiper-zoom-target">
													<img
														draggable="false"
														src="https://picsum.photos/720/1280"
														class="swiper-lazy"
													/>
												</div>
											</div>
										</div>
										<div
											class="swiper-lazy-preloader swiper-lazy-preloader-white"
										></div>
									</swiper-slide> -->

                  <div
                    class="swiper-button-next swiper-button-white "
                    slot="button-next"
                  ></div>
                  <div
                    class="swiper-button-prev swiper-button-white"
                    slot="button-prev"
                  ></div>
                  <div class="swiper-pagination" slot="pagination"></div>
                </swiper>
                <div class="swiper--tools--wrapper">
                  <div class="swiper--tools">
                    <div class="swiper--tools--list">
                      <div class="tool zoom-out" @click="zoomOutImage">
                        <svg width="16" height="17" viewBox="0 0 16 17">
                          <g
                            id="Group_223"
                            data-name="Group 223"
                            transform="translate(-0.232 0.44)"
                          >
                            <path
                              id="Path_233"
                              data-name="Path 233"
                              d="M0,0H9.572"
                              transform="translate(3.638 8.82)"
                              fill="none"
                              stroke="#000"
                              stroke-linecap="round"
                              stroke-width="1"
                            />
                            <rect
                              id="Rectangle_214"
                              data-name="Rectangle 214"
                              width="16"
                              height="17"
                              transform="translate(0.232 -0.44)"
                              fill="none"
                            />
                          </g>
                        </svg>
                      </div>
                      <div class="tool zoom-in" @click="zoomInImage">
                        <svg width="16" height="17" viewBox="0 0 16 17">
                          <g
                            id="Group_222"
                            data-name="Group 222"
                            transform="translate(-0.232 0.097)"
                          >
                            <path
                              id="Path_231"
                              data-name="Path 231"
                              d="M0,0H9.572"
                              transform="translate(8.423 3.356) rotate(90)"
                              fill="none"
                              stroke="#000"
                              stroke-linecap="round"
                              stroke-width="1"
                            />
                            <path
                              id="Path_232"
                              data-name="Path 232"
                              d="M0,0H9.572"
                              transform="translate(3.638 8.142)"
                              fill="none"
                              stroke="#000"
                              stroke-linecap="round"
                              stroke-width="1"
                            />
                            <rect
                              id="Rectangle_213"
                              data-name="Rectangle 213"
                              width="16"
                              height="17"
                              transform="translate(0.232 -0.097)"
                              fill="none"
                            />
                          </g>
                        </svg>
                      </div>
                      <div class="tool rotate" @click="rotateImage">
                        <svg
                          id="two-circular-counterclockwise-rotating-arrows-symbol"
                          width="14.945"
                          height="14.945"
                          viewBox="0 0 14.945 14.945"
                        >
                          <g id="_x33__5_">
                            <g id="Group_155" data-name="Group 155">
                              <path
                                id="Path_160"
                                data-name="Path 160"
                                d="M14.478,9.341H9.808a.467.467,0,0,0,0,.934h3.566A6.529,6.529,0,0,1,1.008,8.407H.064a7.468,7.468,0,0,0,13.947,2.681v3.391a.467.467,0,0,0,.934,0V9.808A.435.435,0,0,0,14.478,9.341ZM.467,5.6h4.67a.467.467,0,0,0,0-.934H1.569A6.531,6.531,0,0,1,13.937,6.539h.944A7.468,7.468,0,0,0,.934,3.858V.467A.467.467,0,0,0,0,.467v4.67A.47.47,0,0,0,.467,5.6Z"
                              />
                            </g>
                          </g>
                        </svg>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div class="share-collection-wrap">
                <!-- Share -->
                <div class="record--detail--share">
                  <h5>Share</h5>
                  <div class="record--detail--share--list">
                    <svg
                      class="icon icon-facebook"
                      width="8.466"
                      height="16.419"
                      viewBox="0 0 8.466 16.419"
                    >
                      <path
                        id="facebook-f-brands"
                        d="M6.921,2.726H8.466V.115A20.65,20.65,0,0,0,6.215,0,3.517,3.517,0,0,0,2.46,3.858v2.3H0V9.076H2.46v7.344H5.474s-.59-5.508,0-7.344,2.36,0,2.36,0L8.21,6.157H5.474V4.147c0-.843.234-1.421,1.446-1.421Z"
                      />
                    </svg>

                    <svg
                      class="icon icon-pinterest"
                      width="15.906"
                      height="15.906"
                      viewBox="0 0 15.906 15.906"
                    >
                      <path
                        id="pinterest-brands"
                        d="M15.906,15.953A7.954,7.954,0,0,1,5.6,23.55a8.94,8.94,0,0,0,.988-2.084c.1-.372.494-1.892.494-1.892a2.13,2.13,0,0,0,1.822.914c2.4,0,4.127-2.206,4.127-4.948a4.668,4.668,0,0,0-4.9-4.592c-3.431,0-5.256,2.3-5.256,4.814a3.569,3.569,0,0,0,1.613,3.082c.151.071.231.038.266-.106.026-.109.16-.651.221-.9a.238.238,0,0,0-.055-.228,3.132,3.132,0,0,1-.587-1.815,3.442,3.442,0,0,1,3.592-3.451,3.14,3.14,0,0,1,3.322,3.236c0,2.152-1.087,3.643-2.5,3.643a1.154,1.154,0,0,1-1.177-1.437,15.981,15.981,0,0,0,.657-2.649,1,1,0,0,0-1.007-1.119c-.8,0-1.44.824-1.44,1.931a2.866,2.866,0,0,0,.237,1.18s-.786,3.329-.93,3.951a7.7,7.7,0,0,0-.029,2.283,7.954,7.954,0,1,1,10.852-7.408Z"
                        transform="translate(0 -8)"
                      />
                    </svg>
                    <svg
                      class="icon icon-twitter"
                      width="16.419"
                      height="13.335"
                      viewBox="0 0 16.419 13.335"
                    >
                      <path
                        id="twitter-brands"
                        d="M14.732,51.405c.01.146.01.292.01.438a9.509,9.509,0,0,1-9.574,9.574A9.51,9.51,0,0,1,0,59.907a6.962,6.962,0,0,0,.813.042A6.739,6.739,0,0,0,4.99,58.511a3.371,3.371,0,0,1-3.146-2.334,4.244,4.244,0,0,0,.636.052,3.559,3.559,0,0,0,.886-.115,3.366,3.366,0,0,1-2.7-3.3V52.77a3.389,3.389,0,0,0,1.521.427,3.37,3.37,0,0,1-1.042-4.5,9.565,9.565,0,0,0,6.939,3.521A3.8,3.8,0,0,1,8,51.447a3.368,3.368,0,0,1,5.824-2.3,6.625,6.625,0,0,0,2.136-.813,3.356,3.356,0,0,1-1.479,1.854,6.746,6.746,0,0,0,1.938-.521,7.234,7.234,0,0,1-1.688,1.74Z"
                        transform="translate(0 -48.082)"
                      />
                    </svg>
                  </div>
                </div>
                <!-- End Share -->
                <div class="record--detail--collection" v-if="getUser">
                  <div class="add--button" @click="openCollectionModal">
                    <span class="plus"></span>
                    <p>
                      {{ $t("single.single_add_to_collection") }}
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <!-- End col 7 -->

          <!-- Start col 5 -->
          <div class="col-12 col-xl-4">
            <div class="record__detail__info" ref="sectionInfo">
              <!-- Info Wrapper -->
              <div class="record__detail__info__wrapper">
                <h1 class="title">
                  {{
                    lang == "en" ? recordDetail.title_en : recordDetail.title_tc
                  }}
                </h1>
                <p
                  class="description"
                  v-if="
                    recordDetail.description_en || recordDetail.description_tc
                  "
                >
                  {{
                    lang == "en"
                      ? recordDetail.description_en
                      : recordDetail.description_tc
                  }}
                </p>

                <div class="record__detail__data__list">
                  <!-- Taxonomy  -->
                  <div class="record__detail__data__single">
                    <p>UID</p>
                    <ul>
                      <li>{{ recordDetail.uid | upper }}</li>
                    </ul>
                  </div>
                  <!-- End Taxonomy -->
                  <!-- Taxonomy  -->
                  <div class="record__detail__data__single">
                    <p>
                      {{ $t("single.single_media_type") }}
                    </p>
                    <ul>
                      <li v-for="el in mediaTypeEl" :key="el.slug">
                        {{ lang == "en" ? el.title_en : el.title_tc }}
                      </li>
                    </ul>
                  </div>
                  <!-- End Taxonomy -->
                  <!-- Taxonomy  -->
                  <div class="record__detail__data__single">
                    <p>
                      {{ $t("single.single_primary_elements") }}
                    </p>
                    <ul>
                      <li v-for="el in primaryEl" :key="el.slug">
                        {{ lang == "en" ? el.title_en : el.title_tc }}
                      </li>
                    </ul>
                  </div>
                  <!-- End Taxonomy -->
                  <!-- Taxonomy  -->
                  <div class="record__detail__data__single">
                    <p>{{ $t("single.single_dimensions") }}</p>
                    <ul>
                      <li>{{ recordDetail.dimensions }}</li>
                    </ul>
                  </div>
                  <!-- End Taxonomy -->
                  <!-- Taxonomy  -->
                  <div class="record__detail__data__single">
                    <p>{{ $t("single.single_year_of_release") }}</p>
                    <ul>
                      <li>
                        {{ recordDetail.start_year }} -
                        {{ recordDetail.end_year }}
                      </li>
                    </ul>
                  </div>
                  <!-- End Taxonomy -->
                  <!-- Meta  -->
                  <div
                    v-for="el in this.recordDetail.meta"
                    :key="el.id"
                    class="record__detail__data__single"
                  >
                    <p>{{ lang == "en" ? el.key_en : el.key_tc }}</p>
                    <ul>
                      <li>{{ lang == "en" ? el.value_en : el.value_tc }}</li>
                    </ul>
                  </div>
                  <!-- End Meta -->
                </div>
              </div>
              <!-- End Info Wrapper -->
            </div>
          </div>
        </div>
      </div>
      <!-- End Record Detail wrapper -->

      <!-- Record Related -->
      <RecordRelated :recordId="recordDetail.id" />
      <!-- End Record Related -->
    </div>
    <!-- Add Collection Modal -->
    <CollectionModal
      v-if="getUser"
      :collectionOpened="collectionModalOpened"
      :collectionIds="collectionIdList"
      :apiRecordDetail="apiRecordDetail"
      :recordId="recordDetail.id"
      @closeCollectionModal="handleCloseCollectionModal"
    />
  </div>
</template>
<script>
import Cookies from "js-cookie";
import { mapState, mapActions, mapGetters } from "vuex";
import CollectionModal from "@/components/common/CollectionModal";
import RecordRelated from "@/views/Record/parts/RecordRelated";
import { gsap } from "gsap";
import { ScrollTrigger } from "gsap/ScrollTrigger";
gsap.registerPlugin(ScrollTrigger);

export default {
  name: "RecordDetail",
  components: {
    RecordRelated,
    CollectionModal
  },
  data() {
    return {
      lang: Cookies.get("HKGA-MAINSITE-LANGUAGE"),
      // Swiper settings
      swiperOptions: {
        zoom: {
          maxRatio: 2,
          toggle: false
        },
        // allowTouchMove: false,
        centeredSlides: true,
        lazy: true,
        centeredSlidesBounds: true,
        pagination: {
          el: ".swiper-pagination",
          type: "fraction"
        },
        navigation: {
          nextEl: ".swiper-button-next",
          prevEl: ".swiper-button-prev"
        },
        breakpoints: {
          // when window width is >= 640px
          1200: {
            allowTouchMove: false
          }
        }
        // Some Swiper option/callback...
      },

      // Record details
      rotateDegress: 0,
      scaleRatio: 1,
      recordDetail: Object,
      taxList: [],
      primaryEl: Object,
      mediaTypeEl: Object,
      collectionIdList: [],
      collectionModalOpened: false,
      metaType: []
    };
  },
  filters: {
    upper(value) {
      if (!value) return "";
      return value.toUpperCase();
    }
  },
  computed: {
    ...mapGetters("common", ["getUser"]),

    swiper() {
      return this.$refs.mySwiper.$swiper;
    },
    recordSlug() {
      return this.$route.params.slug;
    }
  },
  mounted() {},

  beforeMount() {
    this.s3RootPath = this.s3Path;
  },
  created() {
    this.apiRecordDetail();
  },

  methods: {
    ...mapActions("common", ["setPreventScrolling"]),

    handleCloseCollectionModal(value) {
      this.collectionModalOpened = value.modalState;
      this.apiRecordDetail();
      this.setPreventScrolling(false);
    },

    openCollectionModal() {
      this.collectionModalOpened = !this.collectionModalOpened;
      this.setPreventScrolling(true);
    },

    rotateImage() {
      let activeSlideEl = this.swiper.slides[this.swiper.activeIndex];
      setTimeout(() => {
        if (this.rotateDegress == 0) {
          activeSlideEl.querySelector(
            ".rotate-wrapper"
          ).style.transitionProperty = "unset";
        }

        this.rotateDegress += 90;

        // this.swiper.slides[
        //   this.swiper.activeIndex
        // ].style.webkitTransform = `rotate(${this.rotateDegress}deg)`;

        let imageWidth = activeSlideEl.querySelector(".swiper-lazy").width;

        let imageHeight = activeSlideEl.querySelector(".swiper-lazy").height;

        console.log(imageWidth);
        console.log(imageHeight);
        if (
          imageWidth > imageHeight &&
          (this.rotateDegress == 90 || this.rotateDegress == 270)
        ) {
          this.scaleRatio = 0.7;
        } else if (imageWidth > imageHeight) {
          this.scaleRatio = 1;
        }

        if (
          imageHeight > imageWidth &&
          (this.rotateDegress == 90 || this.rotateDegress == 270)
        ) {
          this.scaleRatio = 1;
        } else if (imageHeight > imageWidth) {
          this.scaleRatio = 0.7;
        }

        activeSlideEl.querySelector(
          ".rotate-wrapper"
        ).style.webkitTransform = `rotate(${this.rotateDegress}deg) scale(${this.scaleRatio})`;
        if (this.rotateDegress == 360) {
          setTimeout(() => {
            activeSlideEl.querySelector(
              ".rotate-wrapper"
            ).style.webkitTransform = "none";
            activeSlideEl.querySelector(
              ".rotate-wrapper"
            ).style.transitionProperty = "none";
          }, 300);

          this.rotateDegress = 0;
        }
      }, 400);
    },

    zoomInImage() {
      let activeSlideEl = this.swiper.slides[this.swiper.activeIndex];

      this.swiper.zoom.in();
      activeSlideEl.querySelector(
        ".rotate-wrapper"
      ).style.webkitTransform = `rotate(${this.rotateDegress}deg) scale(${this.scaleRatio})`;
      this.swiper.navigation.nextEl.classList.add("swiper-button-disabled");
      this.swiper.navigation.prevEl.classList.add("swiper-button-disabled");
    },

    zoomOutImage() {
      let activeSlideEl = this.swiper.slides[this.swiper.activeIndex];
      this.swiper.zoom.out();
      activeSlideEl.querySelector(
        ".rotate-wrapper"
      ).style.webkitTransform = `rotate(${this.rotateDegress}deg) scale(${this.scaleRatio})`;
      this.swiper.navigation.nextEl.classList.remove("swiper-button-disabled");
      this.swiper.navigation.prevEl.classList.remove("swiper-button-disabled");
      this.swiper.navigation.init();
      this.swiper.navigation.update();
    },

    handleSlideChange() {
      console.log("end");
      this.rotateDegress = 0;
    },

    scrollGalleryPin() {
      let _this = this;
      ScrollTrigger.matchMedia({
        "(min-width: 1200px)": function() {
          gsap.timeline({
            scrollTrigger: {
              trigger: ".record--detail--wrapper",
              start: "top 40px",
              // endTrigger: ".record--detail--gallery--wrapper",
              end: "bottom bottom",
              // end: () => `+=${_this.$refs.sectionInfo.offsetHeight} bottom`,
              // end:"bottom bottom",
              // markers: true,
              scrub: true,
              // pin: true
              // pinSpacing:"margin",
              pin: ".record--detail--gallery--wrapper"
            }
          });
        }
      });
    },

    async apiRecordDetail() {
      try {
        const recordSlug = this.recordSlug;
        const response = await fetch(
          `${process.env.VUE_APP_BASE_API_URL}/record/${recordSlug}?meta=1&term=1&collection=1`,
          {
            method: "GET",
            mode: "cors",
            cache: "default"
          }
        );
        if (!response.ok) {
          throw new Error("API Error");
        }
        this.recordDetail = await response.json();

        this.primaryEl = this.recordDetail.term.filter(
          term => term.taxonomy.slug == "primary-elements"
        );

        this.mediaTypeEl = this.recordDetail.term.filter(
          term => term.taxonomy.slug == "media-type"
        );

        this.metaType = new Set(
          this.recordDetail.meta.map((item, index, array) => {
            return {
              key_en: item["key_en"],
              key_tc: item["key_tc"]
            };
          })
        );

        this.collectionIdList = this.recordDetail.collection.map(
          item => item.id
        );

        this.scrollGalleryPin();
      } catch (error) {
        console.log(error);
      }
    }
  }
};
</script>

<style lang="scss" scoped>
.page {
  padding-top: 0px !important;
  padding-bottom: 40px;
  // min-height: calc(100vh - 170px);
  @include respond-to(screen-md) {
    // min-height: calc(100vh - 240px);
    padding-top: 50px !important;
    padding-bottom: 50px;
  }
}
.inner-container {
  padding: 0 0px;
  @include respond-to(screen-md) {
    padding: 0 40px;
  }
}
.record {
  &--detail {
    &--wrapper {
      // display: flex;
      // height: 100%;
      margin-bottom: 40px;
    }
    &--gallery {
      &--wrapper {
        display: flex;
        // margin-right: 30px;
        position: relative;
        // height:100vh;
        // height: calc(100vh - 40px);
        height: auto;
        flex-flow: column;
        align-content: space-between;
        box-sizing: border-box;
        @include respond-to(screen-md) {
          // height: calc(100vh - 40px);
        }
        @include respond-to(screen-lg) {
          height: calc(100vh - 40px);
        }
        @include respond-to(screen-xl) {
          height: calc(100vh - 40px);
          // height: auto;
        }
        .swiper-container {
          @include respond-to(screen-xl) {
            // height: 90%;
          }
        }
        .swiper {
          &--tools {
            padding-right: 20px;
            @include respond-to(screen-md) {
              padding-right: 0;
            }
            &--wrapper {
              z-index: 2;
              position: absolute;
              // right: 0;
              // bottom: 8%;
              right: 0px;
              // bottom: 40px;

              // edit by carol
              // position: relative;
              // bottom: 30px;
              // bottom:20px;
              // right: 10px;
              // bottom: 50%;
              // transform: translateY(calc(-50% + 60px));
              // z-index: 999;
              bottom: 30px;
              @include respond-to(screen-md) {
                // bottom:10px;
              }
              @include respond-to(screen-lg) {
                bottom: 30px;
              }
              @include respond-to(screen-xl) {
                bottom: 30px;
              }
            }
            &--list {
              display: flex;
              // flex-direction: column;

              // edit by carol

              .tool {
                // margin-top: 10px;
                margin-left: 10px;
                // background-color: rgba($color-white, .5);
                cursor: pointer;
                position: relative;
                // background-color: rgba($color-white, 0.5);
                // padding: 5px;

                // &:after{
                // 	content:'';
                // 	display: block;
                // 	padding-top: 100%;
                // 	background-color: rgba($color-white, .5);
                // }
              }
            }
          }
        }
        .swiper-container {
          // height: calc(50vh - 100px);
          // padding-bottom: 85px;
          margin-bottom: 30px;
          //   max-width: 980px;
          position: relative;
          overflow: hidden;
          width: 100%;
          // height: calc(100vh - 120px);
          // @include respond-to (screen-lg){
          // 	height:auto;
          // }
          .swiper-slide {
            // background-color: #d9d9d9;
            transition: all 200ms ease-in-out;

            &-wrapper {
              // position: relative;
              // height: 100%;
              // width: 100%;
              // overflow: hidden;

              //edit by carol
              width: 100%;
              height: 100%;
              // padding: 50px;
              display: flex;
              // justify-content: center;
              // align-items: center;
              position: relative;
              overflow: hidden;
              margin-bottom: 15px;
              @include respond-to(screen-md) {
                margin-bottom: 20px;
                max-height: calc(100vh - 160px);
                // max-width: 800px;
              }
              @include respond-to(screen-lg) {
                // max-width: 800px;
                // max-height: 100%;
                max-height: calc(100vh - 160px);
                height: auto;
                // height: calc(100vh - 200px);
                margin-left: auto;
                margin-right: auto;
              }

              @include respond-to(screen-xl) {
                max-width: 980px;
                // margin-bottom: 20px;
                height: calc(100vh - 160px);
                margin-left: auto;
                margin-right: auto;
              }
            }
            .swiper-zoom-container {
              // position: absolute;
              // top: 0;
              // left: 0;
              // width: 100%;
              // height: 100%;
              display: block !important;
              &:after {
                content: "";
                display: block;
                padding-top: 75%;
                // padding-top: 56.25%;
                // position:absolute;
                // top:0;
                // left:0;
              }
            }
            .rotate-wrapper {
              transition: all 200ms ease-in-out;
              position: absolute;
              top: 0;
              left: 0;
              width: 100%;
              height: 100%;
            }
            img {
              // width: auto;
              // height: 100%;
              // object-fit: contain;
              transition: transform 0.5s ease-in;
              // // height: 100%;
              // margin: auto;
              // display: flex;

              //edit by carol
              object-fit: contain;
              // max-width: 100%;
              // max-height: 100%;
              width: 100%;
              height: 100%;
              // width: auto;
              // height: auto;
            }
          }

          .swiper-pagination {
            text-align: left;
            display: inline-block;
            width: auto;
            position: relative;
            padding-left: 20px;
            @include respond-to(screen-md) {
              padding-left: 0px;
            }
          }
          .swiper-pagination-fraction {
            // bottom: -30px;
            // position: absolute;
            z-index: 9;
            bottom: 0;
          }
          .swiper-button-white {
            // mix-blend-mode: multiply;
          }
          .swiper-button {
            &-prev {
              position: absolute;
              top: 0;
              left: 0;
              width: 50%;
              height: 100%;
              // height: 120px;
              // cursor: url("~@/assets/images/swiper-prev.png"), auto;
              cursor: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='29.2' height='100.491' viewBox='0 0 29.2 100.491'%3E%3Cpath id='Path_337' data-name='Path 337' d='M-3477.552-299l-28.19,50,28.19,50' transform='translate(3506.316 299.246)' fill='none' stroke='%23000' stroke-width='1'/%3E%3C/svg%3E%0A"),
                auto;
              &::after {
                content: "";
              }
            }
            &-next {
              position: absolute;
              top: 0;
              right: 0;
              width: 50%;
              height: 100%;
              // height: 120px;
              // cursor: url("~@/assets/images/swiper-next_low_res.png"), auto;
              cursor: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='29.199' height='100.491' viewBox='0 0 29.199 100.491'%3E%3Cpath id='Path_336' data-name='Path 336' d='M-3505.742-299l28.19,50-28.19,50' transform='translate(3506.178 299.246)' fill='none' stroke='%23000' stroke-width='1'/%3E%3C/svg%3E "),
                auto;
              &::after {
                content: "";
              }
            }
          }
        }
      }
    }
    &--share {
      margin-right: auto;
      display: flex;
      align-items: center;
      h5 {
        font-size: 16px;
        font-family: "Graphik Compact", sans-serif;
        font-weight: 100;
        // margin-bottom: 10px;
        line-height: 1;
        margin-right: 20px;
      }

      &--list {
        .icon {
          width: 15px;
          height: 15px;
          margin-right: 15px;
          cursor: pointer;
        }
      }
    }
    &--collection {
      // position: relative;
      // top: -20px;
      // margin-top: 20px;
      // position: absolute;
      // position: relative;
      // right: 0;
      // bottom: 85px;
      width: auto;
      z-index: 1;
      // position: absolute;
      // bottom: 80px;
      // right: 0;
      .add--button {
        cursor: pointer;
        display: flex;
        justify-content: flex-end;
        align-items: center;
        // margin-bottom: 30px;
        @include transition(all 300ms ease-in-out);
        .plus {
          display: inline-block;
          width: 20px;
          height: 20px;
          background: linear-gradient(#000, #000), linear-gradient(#000, #000);
          background-position: center;
          background-size: 50% 2px, 2px 50%; /*thickness = 2px, length = 50% (25px)*/
          background-repeat: no-repeat;
        }
        > p {
          display: inline-block;
          font-size: 16px;
        }
        &:hover {
          opacity: 0.5;
        }
      }
    }
    &--related {
      margin-top: 50px;
      padding: 0 20px;
      margin-bottom: 60px;
      @include respond-to(screen-md) {
        margin-top: 50px;
        padding: 0;
        margin-bottom: 60px;
      }
      @include respond-to(screen-lg) {
        margin-top: 100px;
      }
      h2 {
        font-family: "Graphik Compact", sans-serif;
        font-weight: 100;
        font-size: 32px;
        margin-bottom: 24px;
      }
      &--list {
        display: inline-flex;
        flex-direction: row;
        flex-wrap: wrap;
        gap: 20px;
      }
    }
  }

  &__detail {
    padding-top: 0px !important;
    padding-bottom: 40px;
    @include respond-to(screen-md) {
      padding-top: 40px !important;
    }

    &__info {
      // overflow-y: scroll;
      // width: 30%;
      // height: calc(100vh - 245px);
      // padding-left:20px;
      // margin-top:60px;
      padding-left: 20px;
      padding-right: 20px;
      @include respond-to(screen-md) {
        padding-left: 0px;
        padding-right: 20px;
      }
      @include respond-to(screen-lg) {
        padding-left: 20px;
        padding-right: 20px;
      }
      @include respond-to(screen-xl) {
        padding-left: 20px;
        padding-left: 20px;
      }
      &__wrapper {
        padding-bottom: 0px;
        @include respond-to(screen-md) {
          padding-bottom: 30px;
        }
        @include respond-to(screen-xl) {
          padding-bottom: 30px;
        }
        .title {
          font-size: 34px;
          line-height: 1.2;
        }
        .description {
          font-size: 18px;
        }
      }
    }
    &__data {
      &__list {
        margin-top: 40px;
      }
      &__single {
        margin-bottom: 20px;
        text-transform: capitalize;
        p {
          color: #7c7c7c;
          font-size: 14px;
        }
        ul {
          list-style: none;
          margin: 0;
          padding: 0;
          li {
            font-size: 16px;
          }
        }
      }
      &__single:last-of-type {
        margin-bottom: 0;
      }
    }
  }
}
.group--wrapper {
  position: relative;
  overflow: hidden;
  // height: calc(100vh - 90px);
  // margin-top: auto;
}
.share-collection-wrap {
  display: flex;
  // margin-top: auto;
  margin-bottom: 40px;
  padding: 0 20px;
  @include respond-to(screen-md) {
    padding: 0;
    // margin-bottom: auto;
    margin-top: auto;
    margin-bottom: 30px;
  }
  @include respond-to(screen-xxl) {
    margin-top: 0;
  }
}
</style>
